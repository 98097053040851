import Resful from "@/services/resful.js";
// import "bootstrap/dist/css/bootstrap.css";
// import "bootstrap/dist/js/bootstrap";

export default {
    data: function () {
        return {
            email: "",
            code: "",
            password: ""
        }
    },
    methods: {
        updatePassword: async function () {
            if (!this.email || !this.code || !this.password) {
                this.$swal({
                    title: "Error",
                    text: "Fields cannot be empty",
                    type: "error"
                });
                return;
            }
            let query = {
                email: this.email,
                old_password: this.code,
                new_password: this.password
            };
            let { data } = await Resful.post('/auth/update-password', JSON.stringify(query));
            if (data.message) {
                this.$swal({
                    title: "Error",
                    text: data.message,
                    type: "error"
                });
                return;
            }
            await this.$swal({
                title: "Success",
                text: "Password has been changed",
                type: "success"
            })
            this.$router.push("/login")
        }
    }
}